<template>
  <div class="activity-rules">
    <div class="container" v-html="text">
    </div>
  </div>
</template>
<script>
import { GetActInfoAPI } from '../api/api'
export default{
  data() {
    return {
      text:''
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    async init(){
      const { act_id } = this.$route.query
      const { data:{ act_info}} = await GetActInfoAPI({type:act_id})
      this.text = act_info.rule;
    },
  },
}
</script>
<style lang="scss" scoped>
  .activity-rules{
    min-height: 80vh;
    padding: 10px;
  }
</style>